<template>
  <b-card>
    <div
      v-for="(item, index) in changelog.reverse()"
      :key="item.version"
    >
      <hr v-if="index !== 0" />
      <h5>{{ item.version }}</h5>
      <ul>
        <li
          v-for="change in item.changes"
          :key="item.version + change"
        >
          {{ change }}
        </li>
      </ul>
    </div>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'

export default {
  components: {
    BCard,
  },
  computed: {
    changelog() {
      return [
        {
          version: '1.0.0',
          date: '2022-04-10',
          changes: [
            'Added all videos section',
            'Added Video working group property',
            'Added Video speaker property',
            'Added Video speaker, working group, congress filters',
            'Updated video list columns',
            'User list database fetch speed optimization',
            'Spring Boot upgrade to 2.6.5',
            'Added Digital Book functionality',
          ],
        },
      ]
    },
  },
}
</script>

<style lang="scss" scoped>
h5 {
  margin: 1rem 0;
}

ul {
  padding: 2rem;
  background-color: #f9fafb;
  border-radius: 8px;
  list-style-type: '\2013';
  text-indent: 5px;
}
</style>
